import { gql } from '../types/generated';

export const BTC_PIPELINE_FRAGMENT = gql(`
  fragment BTCPipelineFields on MediaPipeline {
    id
    activeSlateCode
    feedType
    programSource
    status
    sourceSystem
    scheduledStartDateTime
    streamState
    resumedFromDateTime
    eviEnabled
    eviServerStatus
    media {
      id
      mediaType
      feedType
    }
    alerts {
      active
      clearedDateTime
      createdDateTime
      id
      message
    }
  }
`);

export const BTC_CONTENT_FRAGMENT = gql(`
  fragment BTCContentFields on Content {
    callSign
    feedType
    gamePk
    mediaId
    language
    assets {
      adStatus
      assetId
      cdn
      provider
      tier
      state
      videoId
    }
    mediaState {
      contentExperience
      mediaType
      state
    }
  }
`);

export const MULTICAST_MAPPING_FRAGMENT = gql(`
  fragment MulticastMappingFields on PipelineMulticastMapping {
    id
    callSign
    sourceLocation
    multicastAddress
    priority
  }
`);
